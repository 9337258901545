export class CommonRoutes {
    public static SESSIONS="sessions";
    public static AUTH="auth";
    public static REGISTER="register";
    public static LOGIN="login";
    public static FEEDBACK="feedback";
    public static RESET_PASSWORD="reset-password";
    public static SESSIONS_DETAILS="session-detail"
    public static CREATE_SESSION="create-session";
    public static MENTOR_DIRECTORY="mentor-directory";
    public static CREATED_BY_ME="created-by-me";
    public static TABS="tabs";
    public static HOME="home";
    public static PROFILE = "profile"
    public static OTP="otp";
    public static EDIT_PROFILE = 'edit-profile';
    public static HOME_SEARCH ='home-search';
    public static MENTOR_DETAILS='mentor-profile';
    public static TERMS_AND_CONDITIONS='terms-and-conditions';
    public static LANDING_PAGE='landing';
    public static PERSONA_SELECTION='persona-selection';
    public static DASHBOARD='dashboard';
    public static LANGUAGE="language";
    public static HELP='help';
    public static FAQ='faq';
    public static HELP_VIDEOS= 'help-videos';
    public static MENTOR_QUESTIONNAIRE= 'mentor-questionnaire';
    public static ADMIN_DASHBOARD= 'admin-dashboard';
    public static ADMIN= 'admin';
    public static MANAGE_USER= 'manage-user';
    public static BULK_UPLOAD= 'bulk-upload';
    public static MANAGE_SESSION= 'manage-session';
    public static MANAGERS_SESSION = 'managers-create-session';
    public static MANAGERS_SESSION_DETAILS = 'managers-session-details'
    public static CHANGE_PASSWORD = 'change-password'
    public static LOGIN_ACTIVITY = 'login-activity'
}